.spanTitle {
  font-size: 20px;
  font-weight: 700;
  background-color: #022F63;
  border-width: 2px !important;
  border-color: black !important;
  border-style: solid;
  padding-right: 12px;
  padding-left: 12px;
  padding-top: 3px;
  padding-bottom: 3px;
  color: white;
  border-radius: 3px;
}

.spanNumberQuestion {
  color: black;
  border-width: 2px;
  border-color: #022F63;
  border-style: solid;
  padding-left: 2px;
  padding-right: 2px;
}

.spanIcon {
  background-color: rgb(17, 205, 239);
  color: white;
  font-size: 13px;
  display: inline-flex;
  width: 22px;
  height: 30px;
  padding-left: 3px;
  margin-left: 5px;
  justify-content: center;
  align-items: center;
}
