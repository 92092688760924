.key-title {
  color: #616265!important;
  font-weight: bold!important;
}
.key-question {
  color: #CF60AE!important;
  font-weight: bold!important;
}
.f_size{
  font-size: 18px;
}
.f_Wt500{
  font-weight: 500;
}
.f_Wt400{
  font-weight: 400;
}
.mrRight{
 margin-right: 5px;
}