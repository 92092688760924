/* audio files*/

.audio {
    width: 550px
}

.breadcumb{background-color: white;}

@media only screen and (max-width: 600px) {
    .audio {
        width: 400px;
    }
}
@media only screen and (max-width: 450px) {
    .audio {
        width: 300px;
    }
}