.main-hint-box {
  border: 2px solid rgb(17, 205, 239);
  color: black;
  display: block;
  font-size: 18px;
  margin-bottom: 15px;
  padding: 10px;
  /* width: 70%; */
  text-align: center;
}

.render-hint-box {
  font-weight: 700;
  margin: 0px 15px;
  display: inline-block;
}

p {
  margin-bottom: 0px;
}
