#QuestionImage {
  .header {
    text-align: start;
    margin-left: 8px;
    margin-bottom: -8px;
    height: 0px;
    z-index: 2;
  }
  .button {
    display: inline-block;
    color: gray;
    cursor: pointer;
  }
  .button:hover {
    display: inline-block;
    background-color: whitesmoke;
    opacity: 0.7;
    color: black;
    cursor: pointer;
    border-radius: 0.375rem;
  }
  .screen_img{
overflow-y: auto;
    overflow-x: hidden;
    max-height: 60vh;
    z-index: 1;
  }
  img {
    width: 100%;
    height: auto;
  }
  .ant-image{
    display: block;
  }
}
