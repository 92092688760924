@media only screen and (min-height:937px) {

  .header_container {
    padding-block: 115px;
  } 
}
@media only screen and (min-height:769px) and (max-height: 936px) {

  .header_container {
    padding-block: 70px;
  } 
}
@media only screen and (max-height:768px) {

  .header_container {
    padding-block: 60px;
  } 
}