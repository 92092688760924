.academic_report_container {
  padding: 24px;
  height: 100%;

  .body_content {
    padding: 5px;
    overflow-y: auto;
    max-height: calc( 100vh - 240px);
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  .ant-card-head-title{
    padding: 8px 0;
  }
  .ant-card-bordered{
    border-radius: 4px;
  }
  p{
    font-size: 14px;
  }
}