.imgHeader {
  width: 100%
}
.select {
  font-weight: 400;
  text-align: center;
  font-size: 15px;
  /* color: red; */
}

.video-container {
  overflow: hidden;
  position: relative;
  width:60%;
}

.video-container::after {
  padding-top: 56.25%;
  display: block;
  content: '';
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.centeredRow {
  justify-content: center;
}