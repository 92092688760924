#MC4{
  .mutilpleKey {
  width: 30px;
  height: 28px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  background: white;
  font-size: 16px;
}

.mutilpleKeySelected {
  width: 30px;
  height: 28px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  background: rgb(17, 205, 239);
  color: white;
  font-size: 16px;
}

img {
  width: 100%;
  height: auto;
}

.ant-col {
  font-size: 16px;
}

.answerButton {
  min-width: 150px;
}

.answerButton:hover {
  background-color: transparent;
}

.answerButton:focus {
  background-color: transparent;
}

.video-container {
  overflow: hidden;
  position: relative;
  width: 60%;
}

.video-container::after {
  padding-top: 56.25%;
  display: block;
  content: '';
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.centeredRow {
  justify-content: center;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  /* border-left-style: hidden;
  border-right-style: hidden; */
  border-top-width: 0px !important;
  /* border-top-style: solid; */
  /* border-top-color: #1675bd; */
}

td,
th {
  /* border: 1px solid #1675bd; */
  text-align: left;
  padding: 8px;
}

.checkedBox {
  background-color: red;
  border-color: red;
  width: 25px;
  height: 25px;
}
.check-false input[type='radio']:checked {
  background-color: red;
  border-color: red;
}
.check-true input[type='radio']:checked {
  background-color: green;
  border-color: green;
}

}